<template>
  <div class="col-md-6 d-flex flex-column justify-content-center align-items-start">
    <h1 class="fw-bold text-shadow">{{ nombreCompleto }}</h1>
    <h4 class="text-white text-shadow text-start">{{ descripcion }}</h4>
    <h5>{{ especializacion }}</h5>
    <div class="d-flex flex-column align-items-center w-100 justify-content-center">
      <a :href="urlCV" class=" text-decoration-none" download="cv_moha.pdf">
        <button class="btn cv btn-primary my-3">
        <span>
          <i class="fa-solid fa-download"></i>
          CV
        </span>
      </button>
      </a>
    </div>
  </div>
</template>
  
<script>
export default {
  name: "PerfilUsuario",
  data() {
    return {
      nombreCompleto: "Mohamed El Attar Yalaoui",
      descripcion: "Programador web Full-Stack | PHP | Laravel & Vue.js",
      especializacion: "Especializado en PHP, Laravel, Vue, Html, css, js +3 años de experiencia",
      urlCV: "cv_moha.pdf",
    };
  },
};
</script>
  
<style>
.text-shadow {
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.cv {
  display: flex;
  justify-content: center;
  width: 150px;
  border-radius: 65px;
  font-size: 30px;
  color: white;
}

.cv:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  transition: box-shadow 0.3s ease-in-out;
}

.cv a {
  text-decoration: none;
  color: white;
}
</style>