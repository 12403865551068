
<template>
    <MenuPage />
    <div class="container">
        <section class="portafolios mt-5">
            <h1>Proyectos</h1>
            <div class="proyectos mt-5">
                <div class="row justify-content-center mt-5 vh-100">
                    <CardPortafolio titulo="Clone Netflix" subtitulo="Pagina web" :ruta_img="Netflix" />
                    <CardPortafolio titulo="Clone Vegan Essentials" subtitulo="Pagina web ecommerce" :ruta_img="Vegan" />
                </div>
            </div>
        </section>
    </div>

    <FooterPage />
</template>

<script>
import MenuPage from '../components/MenuPage.vue'
import CardPortafolio from '../components/CardPortafolio.vue'
import bgImgNetflix from '../assets/netflix.jpg'
import bgImgVegan from '../assets/vegan.jpg'
import FooterPage from '../components/FooterPage.vue'
export default {
    name: 'PortafolioPage',
    components: {
        MenuPage,
        CardPortafolio,
        FooterPage
    },
    data() {
        return {
            Netflix: bgImgNetflix,
            Vegan: bgImgVegan
        }
    }
}
</script>