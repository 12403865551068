<template>
  <router-view></router-view>
</template>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
  background: linear-gradient(315deg, #FFA500, #33CCCC);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Montserrat';
  font-weight: 700;
}

body,
p,
span,
a {
  font-family: 'Roboto';
  font-weight: 400;
}

a {
  color: black;
}

.btn {
  background-color: #FFA500;
  border-color: #FFA500;
  border-radius: 2rem;
}

.btn:hover {
  background-color: #33CCCC;
  border-color: #33CCCC;
}

.text-shadow {
  text-shadow: 2px 2px 4px #000000;
}
</style>
