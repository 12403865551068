<template>
    <MenuPage />
    <div class="container vh-100">
        <section class="sobreMi mt-5">
            <h1 class="fw-bold">Sobre mí</h1>
            <div class="row">
                <div class="col-md-6 mt-5">
                    <img src="./assets/perfil.png" class="img_perfil" alt="Moha" title="Moha" />
                </div>
                <div class="col-md-6 mt-5">
                    <p class="text-start">Me llamo Mohamed El Attar y tengo 25 años, soy un programador web, tengo +3 años de experiencia como programador, 
                    con conocimientos en HTML5, JS, jQuery, ajax, php, Laravel, Vue, tengo algunos proyectos creados con Vue, como este mismo portafolio, aparte se crear APIs y por último tengo conocimientos básicos en C.</p>
                    <p class="text-start">Me apasiona la programación, sobre todo si se utiliza algún framework, aparte de saber hacer páginas webs, 
                    se hacer aplicaciones webs, que va de lo más fácil a al complejo, que puede ser una página web de contacto, un e-commerce, 
                    una aplicación comercial, un gestor para subir catálogo de productos de una web, e incluso empecé a crear un ERP.
                    </p>
                </div>
            </div>
        </section>
    </div>
    <FooterPage />
</template>
<script>
import MenuPage from './components/MenuPage.vue';
import FooterPage from './components/FooterPage.vue';
export default
{
    name: 'SobreMiPage',
    components: {
        MenuPage,
        FooterPage
    }
}
</script>

<style>
.img_perfil
{
  width: 43%;
  border-radius: 50%;
}
</style>
