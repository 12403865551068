import { createApp } from 'vue'
import {createRouter, createWebHistory} from 'vue-router'
import App from './App.vue'
import MenuPage from './components/MenuPage.vue'
import routes from './routers/routes'
const router = createRouter({
    history: createWebHistory(),
    routes
})

// app.use(VueRouter)
const app = createApp(App)
app.use(router)
app.component('MenuPage', MenuPage);

app.mount('#app')
