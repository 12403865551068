<template>
    <section class="contact-section">
        <div class="container py-5">
            <div class="row justify-content-center vh-100">
                <div class="col-lg-8">
                    <div class="contact-form card shadow-lg p-4 rounded-3">
                        <h2 class="text-center text-uppercase text-black mb-5">Ponte en contacto</h2>
                        <form @submit.prevent="enviarFormulario">
                            <div class="mb-4 position-relative">
                                <i class="fa fa-user icon-input"></i>
                                <input type="text" class="form-control ps-5" id="nombre" placeholder="Nombre completo"
                                    v-model="contacto.nombre" required>
                            </div>
                            <div class="mb-4 position-relative">
                                <i class="fa fa-envelope icon-input"></i>
                                <input type="email" class="form-control ps-5" id="email" placeholder="Correo electrónico"
                                    v-model="contacto.email" required>
                            </div>
                            <div class="mb-4 position-relative">
                                <i class="fa fa-tag icon-input"></i>
                                <input type="text" class="form-control ps-5" id="asunto" placeholder="Asunto"
                                    v-model="contacto.asunto">
                            </div>
                            <div class="mb-4">
                                <textarea class="form-control" id="mensaje" rows="6" placeholder="Tu mensaje"
                                    v-model="contacto.mensaje" required></textarea>
                            </div>
                            <div class="text-center">
                                <button type="submit" class="btn btn-primary rounded-pill px-4 py-2 text-uppercase">Enviar
                                    mensaje</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import emailjs from 'emailjs-com';
export default {
    name: 'ContactoForm',
    data() {
        return {
            contacto: {
                nombre: '',
                email: '',
                asunto: '',
                mensaje: ''
            }
        };
    },
    methods: {
        async enviarFormulario()
        {
             const serviceID = 'service_d5xtz4v';
            const templateID = 'template_9qwcrra';
            const userID = '_BEVFbY0KUD3qbpwa';

            const templateParams = {
                from_name: this.contacto.nombre,
                from_email: this.contacto.email,
                message: this.contacto.mensaje,
            };
            try {
                const response = await emailjs.send(serviceID, templateID, templateParams, userID);
                alert('Email enviado con éxito!', response.status, response.text);
            } catch (error)
            {
                alert('Error al enviar el mensaje');
            }
        }
    }
};
</script>
  
<style scoped>
.contact-section {
    padding: 60px 0;
}

.contact-section .form-label {
    font-weight: bold;
}

.contact-section .btn {
    padding: 10px 20px;
    font-size: 16px;
}

.icon-input {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: #6c757d;
}

.form-control {
  border-radius: 2rem;
  padding-left: 3rem;
}

.form-control:focus {
  box-shadow: none;
  border-color: #FFA500;
}

.card 
{
  overflow: hidden;
}

.card:hover::before 
{
  top: -20%;
  left: -20%;
  width: 140%;
  height: 140%;
}
</style>
