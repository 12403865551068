<template>
    <div class="col-xs-12 col-md-4 mb-3 p-sm-0 me-sm-3">
        <div class="card">
            <a class="text-decoration-none" href="#ruta-proyecto">
                <img class="rounded-top img-fluid" :src="ruta_img" :alt="titulo" :title="titulo"
                    loading="lazy" />
                <h3 class="mt-2 text-dark">{{ titulo }}</h3>
                <p class="text-dark">{{ subtitulo }}</p>
            </a>
            <div class="informacion text-black">
                <div class="d-flex h-100 centrado justify-content-center">
                    <div class="codigo-web">
                        <button class="btn text-white">WebSite</button>
                        <button class="btn text-white">Repositorio</button>
                    </div>
                </div>
                <div class="lenguajes mb-3">
                    <div class="row">
                        <div class="col-md-3"><span class="btn text-white">HTML5</span></div>
                        <div class="col-md-3"><span class="btn text-white">CSS3</span></div>
                        <div class="col-md-3"><span class="btn text-white">Bootstrap</span></div>
                        <div class="col-md-3"><span class="btn text-white">Vue.js</span></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'CardPortafolio',
    props: {
        titulo: String,
        subtitulo: String,
        ruta_img: String
    }
}
</script>

<style>
.card {
  position: relative;
  overflow: hidden;
  transition: box-shadow 0.3s ease-in-out;
  min-height: 307px;
  border: 0;
}

.card:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.card .informacion {
  display: none;
}

.informacion {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: hsl(0deg 0% 82.82% / 0.80);
  backdrop-filter: blur(10px);
  transition: transform 0.8s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.card:hover .informacion 
{
  display: flex;
}

.codigo-web {
  display: flex;
  justify-content: center; 
  align-items: center;
  flex-grow: 1;
  gap: 20px;
}

.lenguajes
{
  text-align: center;
}

.lenguajes .btn
{
  background-color: black;
  border-color: black;
}

</style>