
<template>
    <MenuPage />
    <ContactoForm />
    <FooterPage />
</template>

<script>
import MenuPage from './components/MenuPage.vue';
import FooterPage from './components/FooterPage.vue'
import ContactoForm from './components/ContactoForm.vue'
export default {
    name: 'ContactPage',
    components: {
        MenuPage,
        FooterPage,
        ContactoForm
    }
}
</script>