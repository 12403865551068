<template>
    <nav class="navbar navbar-expand-lg navbar-light">
        <div class="container-fluid">
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown"
                aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <a class="navbar-brand d-lg-none d-md-none text-white">Mohamed El Attar</a>
            <div class="container collapse navbar-collapse justify-content-between border border-dark rounded-5"
                id="navbarNavDropdown">
                <a class="navbar-brand  d-none d-sm-block text-white" href="#">Mohamed El Attar</a>
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <router-link class="nav-link" to="/">Inicio</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/sobre_mi">Sobre mí</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/proyectos">Proyectos</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/contacto">Contacto</router-link>
                    </li>
                </ul>
                <div class="d-flex justify-content-center">
                    <router-link to="/contacto">
                        <button class="btn btn-primary rounded-5">
                            Contacto
                        </button>
                    </router-link>
                </div>
            </div>
        </div>
    </nav>
</template>

<style>
.nav-link {
    color: white;
}

.nav-link:hover {
    color: white;
}
</style>