<template>
    <MenuPage />
    <div class="container">
        <section class="sectionQuienSoy">
            <div class="row mt-5">
                <PerfilUsuario />
                <div class="col-md-6">
                    <img src="./assets/programacion.webp" class=" rounded-5 img-fluid" alt="Programacion" title="Programacion"
                        loading="lazy" />
                </div>
            </div>
        </section>
        <MisHabilidades />
        <section class="portafolios mt-5">
            <h2 class="fw-bold fs-1">Proyectos</h2>
            <div class="proyectos mt-5">
                <div class="row justify-content-center">
                    <CardPortafolio titulo="Clone Netflix" subtitulo="Pagina web" :ruta_img="Netflix" />
                    <CardPortafolio titulo="Clone Vegan Essentials" subtitulo="Pagina web ecommerce" :ruta_img="Vegan" />
                </div>
                <div class="col-xs-12 col-md-12 text-center mt-5">
                    <button class="btn btn-primary">
                        <router-link class="text-decoration-none text-white" to="/proyectos">Más Proyectos</router-link>
                    </button>
                </div>
            </div>
        </section>
        <RedesSociales />
    </div>
    <FooterPage />
</template>
<script>
import PerfilUsuario from './components/PerfilUsuario.vue'
import FooterPage from './components/FooterPage.vue'
import CardPortafolio from './components/CardPortafolio.vue'
import MisHabilidades from './components/MisHabilidades.vue'
import RedesSociales from './components/RedesSociales.vue'
import bgImgNetflix from './assets/netflix.jpg'
import bgImgVegan from './assets/vegan.jpg'
export default {
    name: 'HomePage',
    components: {
        FooterPage,
        CardPortafolio,
        MisHabilidades,
        RedesSociales,
        PerfilUsuario
    },
    data() {
        return {
            Netflix: bgImgNetflix,
            Vegan: bgImgVegan
        }
    }
}
</script>
<style scoped>
h4 {
    color: rgb(114 114 114);
}
.card {
    border-top: 0;
}

</style>