import HomePage from '../HomePage.vue';
import PortafolioPage from '../components/PortafolioPage.vue';
import SobreMiPage from '../SobreMiPage.vue';
import ContactPage from '../Contact.vue';

const routes = 
[
    {path: '/', component: HomePage},
    {path: '/proyectos', component: PortafolioPage},
    {path: '/sobre_mi', component: SobreMiPage},
    {path: '/contacto', component: ContactPage}
];
export default routes;