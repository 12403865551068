<template>
    <section class="redes_sociales mt-5">
        <h3 class="h1 fw-bold">Sígueme</h3>
        <div class="d-flex justify-content-center">
            <div class="col-md-1 me-2 rounded" v-for="(redSocial, index) in redesSociales" :key="index">
                <a target="_blank" :href="redSocial.enlace">
                    <i :class="['fa-2x', redSocial.icono]"></i>
                </a>
            </div>
        </div>
    </section>
</template>

<script>


export default {
    name : 'RedesSociales',
    data() {
    return {
      redesSociales: [
        { icono: "fa-brands fa-github", enlace: "https://www.github.com/mohaDev102" },
        { icono: "fa-brands fa-linkedin", enlace: "https://www.linkedin.com/in/mohamed-el-attar-a068051b5" },
      ],
    };
  },
};
</script>

<style>
</style>