<template>
    <section class="habilidades mt-5">
        <h2 class="fw-bold fs-1">Mis Habilidades</h2>
        <div class="skills">
            <div class="row mt-5">
                <div class="col-md-3" v-for="(habilidad, index) in habilidades" :key="index">
                    <div class="capa">
                        <i :class="habilidad.icono"></i>
                        <p>{{ habilidad.nombre }}</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

export default {
    name: 'MisHabilidades',
    data() {
        return {
            habilidades: [
                { icono: "devicon-html5-plain colored", nombre: "HTML" },
                { icono: "devicon-css3-plain colored", nombre: "Css3" },
                { icono: "devicon-bootstrap-plain colored", nombre: "Bootstrap" },
                { icono: "devicon-javascript-plain colored", nombre: "JS" },
                { icono: "devicon-jquery-plain colored", nombre: "jQuery" },
                { icono: "devicon-vuejs-plain colored", nombre: "Vue" },
                { icono: "devicon-php-plain colored", nombre: "PHP" },
                { icono: "devicon-laravel-plain colored", nombre: "Laravel" },
                { icono: "devicon-mysql-plain colored", nombre: "Mysql" },
                { icono: "devicon-git-plain colored", nombre: "Git" },
            ],
        };
    },
};


</script>

<style>
.colored {
  font-size: 150px;
}

.capa 
{
  background-color: #99b866;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.5s ease;
}

.capa:hover 
{
  transform: scale(1.05);
}

</style>